<div class="container">
  <p class="heading">{{ title }}</p>
  <button class="close-button" (click)="close.emit()">
    <span>x</span>
  </button>

  <div
    class="inner-container"
    [style.overflow-y]="overflowY"
    [style.max-height]="maxHeight"
  >
    <ng-content></ng-content>
  </div>
</div>
