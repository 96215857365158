<app-map-ui-expanded
  title="{{ 'timePeriod' | translate }}"
  (close)="time.showingSelectionScreen = false"
  [maxHeight]="'none'"
  [overflowY]="'hidden'"
>
  <div class="slider-container">
    <mat-slider
      [min]="time.DEFAULT_MIN_YEAR"
      [max]="time.DEFAULT_MAX_YEAR"
      step="1"
      [discrete]="true"
      [displayWith]="formatLabel"
      (input)="onSliderInput($event)"
    >
      <input
        [value]="time.minYear.value"
        matSliderStartThumb
        id="min-year-input"
      />
      <input
        [value]="time.maxYear.value"
        matSliderEndThumb
        id="max-year-input"
      />
    </mat-slider>
  </div>

  <div class="show-locations-without-date-container">
    <mat-checkbox
      [checked]="time.showLocationsWithoutDate.value"
      (change)="onToggleShowLocationsWithoutDate($event)"
      >Toon locaties zonder datum</mat-checkbox
    >
    <!--    <input type="checkbox" id="show-locations-without-date-checkbox" />-->
    <!--    <label for="show-locations-without-date-checkbox">-->
    <!--      <span>Toon locaties zonder datum</span>-->
    <!--    </label>-->
  </div>
</app-map-ui-expanded>
