<app-map-ui-expanded
  title="{{ 'themes' | translate }}"
  (close)="themes.showingSelectionScreen = false"
>
  <div *ngFor="let theme of themes.all.value">
    <div class="theme-checkbox-container">
      <input
        type="checkbox"
        [id]="theme.nid"
        [name]="theme.nid"
        [value]="theme.nid"
        (change)="themes.toggle(theme.nid)"
        [checked]="themes.isSelected(theme.nid)"
      />
      <label [for]="theme.nid">
        <span>{{ theme.title }}</span>
        <br />
        <span class="theme-head">{{ theme.head }}</span>
      </label>
    </div>
    <div class="separator"></div>
  </div>
</app-map-ui-expanded>
