<div class="map">
  <div class="map-view">
    <div id="mapbox" class="mapboxgl-map"></div>

    <div
      class="theme-button-container"
      *ngIf="routing.getSelectedView() === SelectedView.Locations"
    >
      <app-theme-button *ngIf="!themes.showingSelectionScreen" />
      <app-theme-select *ngIf="themes.showingSelectionScreen" />
    </div>
  </div>
  <div class="map-data">
    <div class="map-data-handle"></div>
    <app-route-steps
      *ngIf="routing.getSelectedView() === SelectedView.SelectedRoute"
    ></app-route-steps>
    <div
      class="map-data-dock"
      id="map_dock"
      *ngIf="
        getSelectedLocation() ||
        routing.getSelectedView() === SelectedView.Routes ||
        routing.getSelectedView() === SelectedView.SelectedRoute ||
        routing.getSelectedView() === SelectedView.Story
      "
    >
      <div class="views-element-container">
        <app-selected-item
          [locationDetails]="getSelectedLocation()"
          *ngIf="
            routing.getSelectedView() === SelectedView.Locations ||
            routing.getSelectedView() === SelectedView.Story
          "
        ></app-selected-item>

        <app-selected-route
          *ngIf="routing.getSelectedView() === SelectedView.SelectedRoute"
        ></app-selected-route>
      </div>
    </div>

    <div
      class="map-data-near"
      *ngIf="
        routing.getSelectedView() === SelectedView.Locations ||
        routing.getSelectedView() === SelectedView.Story
      "
    >
      <div class="pad">
        <h2>
          {{ "exploreArea" | translate }}
        </h2>
        <div class="card-hold promote" id="map_near">
          <div
            class="card"
            *ngFor="
              let locationWithDistance of mapService.locationsClosestToCenter.getValue()
            "
          >
            <a
              (click)="
                mapService.selectLocationByUrlOrId(
                  locationWithDistance.location.url,
                  locationWithDistance.location.nid
                )
              "
            >
              <div class="head">
                <div>
                  <div>
                    <img [src]="locationWithDistance.location.image_small" />
                  </div>
                </div>
              </div>
              <div class="core">
                <span class="type">{{
                  locationWithDistance.location.address
                }}</span
                ><span
                  class="name"
                  [innerHtml]="locationWithDistance.location.title"
                ></span
                ><span class="desc">{{
                  locationWithDistance.location.head
                }}</span>
                <span class="dist">{{
                  UtilService.convertDistanceInKmToString(
                    locationWithDistance.distanceFromCenterInKm
                  )
                }}</span>
              </div>
            </a>
          </div>

          <!--          TODO: Refactor to use components (ignored for now as it seems to mess with the preferred DOM hierarchy for the CSS -->
          <!--          <app-nearby-item></app-nearby-item>-->
        </div>
      </div>
    </div>
  </div>
</div>
