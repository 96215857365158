<div class="theme-select-container">
  <p class="theme-select-heading">
    {{ "themes" | translate }}
  </p>
  <button
    (click)="themes.showingSelectionScreen = false"
    class="close-theme-select-button"
  >
    <span>x</span>
  </button>

  <div class="theme-list-container">
    <div *ngFor="let theme of themes.all.value">
      <div class="theme-checkbox-container">
        <input
          type="checkbox"
          [id]="theme.nid"
          [name]="theme.nid"
          [value]="theme.nid"
          (change)="themes.toggle(theme.nid)"
          [checked]="themes.isSelected(theme.nid)"
        />
        <label [for]="theme.nid">
          <span>{{ theme.title }}</span>
          <br />
          <span class="theme-head">{{ theme.head }}</span>
        </label>
      </div>
      <div class="separator"></div>
    </div>
  </div>
</div>
