<app-audio-player
  [audioUrl]="utmRoutes.selectedStop?.audio"
  *ngIf="utmRoutes.selectedStop?.audio && !shouldShowEnglishAudio"
/>

<app-audio-player
  [audioUrl]="utmRoutes.selectedStop?.audio_english"
  *ngIf="shouldShowEnglishAudio"
/>

<app-edit-button [nid]="utmRoutes.selectedStop?.id"></app-edit-button>

<h2
  [innerHtml]="
    utmTranslate.getAsEnglishIfApplicable(
      utmRoutes.selectedStop,
      'title',
      'title_english'
    )
  "
></h2>
<div
  class="stop-intro-container"
  *ngIf="
    this.utmTranslate.getAsEnglishIfApplicable(
      utmRoutes.selectedStop,
      'intro',
      'intro_english'
    )
  "
>
  <p
    class="stop-intro"
    [innerHTML]="
      this.utmTranslate.getAsEnglishIfApplicable(
        utmRoutes.selectedStop,
        'intro',
        'intro_english'
      )
    "
  ></p>
</div>

<div class="media-container">
  <div *ngIf="utmRoutes.selectedStop?.stories">
    <ng-container *ngFor="let story of utmRoutes.selectedStop?.stories">
      <div
        class="story-head"
        *ngIf="story?.title"
        [hidden]="story.title === utmRoutes.selectedStop?.title"
      >
        <h2>{{ story.title }}</h2>
      </div>

      <app-audio-player [audioUrl]="story.audio" *ngIf="story.audio" />

      <div
        class="views-element-container"
        *ngFor="let mediaItem of story?.mediaItems; let i = index"
      >
        <app-media-item
          [mediaItem]="mediaItem"
          [showTitle]="!(i === 0 && mediaItem.title === story.title)"
          [addTopMargin]="false"
        ></app-media-item>
      </div>
    </ng-container>
  </div>

  <a
    class="route-next route-button"
    (click)="utmRoutes.selectNextStop(0)"
    *ngIf="utmRoutes.hasNextStop()"
  >
    Volgende stop
  </a>
</div>

<app-feedback
  [feedbackItemId]="utmRoutes.selectedStop?.id"
  feedbackGivenText="feedbackGiven"
  feedbackQuestionText="routeStopFeedbackQuestion"
></app-feedback>
