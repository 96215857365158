<div *ngIf="mediaItem">
  <div
    class="media-item"
    [ngClass]="{
      'has-head': hasHead(),
      'media-item-no-top-margin': !addTopMargin
    }"
  >
    <div class="core" [ngClass]="[showFullWidth ? 'core-full-width' : '']">
      <div class="core-name" *ngIf="showTitle">
        <h4 [innerHtml]="mediaItem.title"></h4>
      </div>

      <app-audio-player
        *ngIf="mediaItem.has_audio"
        [audioUrl]="mediaItem.media_file"
      ></app-audio-player>

      <div class="image-embed" *ngIf="mediaItem.type === MediaItemType.Image">
        <app-image-viewer
          [imageSrc]="mediaItem.image_small"
          [imageAlt]="mediaItem.caption"
        ></app-image-viewer>
      </div>

      <div class="AR-embed" *ngIf="mediaItem.type === MediaItemType.AR">
        <app-ar-360-viewer
          [imageSrc]="mediaItem.ar_360_photo"
          [videoSrc]="mediaItem.ar_360_video"
          [defaultPitch]="mediaItem.ar_360_photo_default_pitch"
          [defaultYaw]="mediaItem.ar_360_photo_default_yaw"
          [defaultZoom]="mediaItem.ar_360_photo_default_zoom"
        ></app-ar-360-viewer>
      </div>

      <div
        class="video-embed"
        *ngIf="
          mediaItem.type === MediaItemType.Embed ||
          mediaItem.type === MediaItemType.Video
        "
      >
        <div>
          <iframe
            [src]="mediaItem.embed_url | safe"
            *ngIf="mediaItem.type === MediaItemType.Embed"
          ></iframe>

          <video
            controls=""
            preload="auto"
            *ngIf="mediaItem.type === MediaItemType.Video"
          >
            <source [src]="mediaItem.media_file" />
          </video>
        </div>
      </div>

      <div class="media-source">
        <div class="views-element-container">
          <div>
            <span [innerHtml]="mediaItem.caption"></span>
            <span *ngIf="mediaItem?.source_name"
              >{{ "source" | translate }}:

              <!--  TODO: Refactor into component -->
              <ng-container *ngIf="mediaItem?.source_link">
                <a
                  [href]="mediaItem.source_link"
                  target="_blank"
                  [innerHtml]="mediaItem.source_name"
                ></a>
              </ng-container>
              <ng-container *ngIf="!mediaItem?.source_link">
                {{ mediaItem.source_name }}
              </ng-container>
            </span>

            <app-license [licenseLabel]="mediaItem.license"></app-license>
          </div>
        </div>
      </div>

      <app-organisations
        [organisations]="mediaItem.organisations"
      ></app-organisations>
    </div>
    <div class="head" *ngIf="hasHead()">
      <h4 [innerHtml]="mediaItem.title" *ngIf="showTitle"></h4>
      <div [innerHtml]="mediaItem.text"></div>
    </div>
  </div>
</div>
